#paginasecundaria{
    z-index: 1;
    background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
    min-height: 100vh; 
    
}

#titulo3{
    color: white;
    font-size: 16px;
    text-align: justify;
}

#titulo1_{
    color: white;
}

#seguir_1{
    width: 150px;
    height: 50px;
    font-size: 18px;
    background:#330867 70%;
    border-color: white;
    
}

#contenedortexto{
    padding-right: 4%;
    padding-left: 4%;
}
